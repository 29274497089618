import '../scss/vendor.scss';
require('intersection-observer');
/*eslint-disable */
import objectFitImages from 'object-fit-images';
import { tns } from 'tiny-slider/src/tiny-slider.js';
/* eslint-enable */

// Object fit function to ensure images resize correctly in IE11/Edge
objectFitImages();

/* eslint-disable */

// forEach method
var forEach = function(array, callback, scope) {
	for (var i = 0; i < array.length; i++) {
		callback.call(scope, i, array[i]);
	}
};

// Default carousel (Pod, Teaser)
const carouselContainers = document.querySelectorAll('.carousel__container');
// Array to track which carousels have been viewed
const viewedCarousels = [];

forEach(carouselContainers, function (index, value) {
	let control = value.dataset.name;
	const carouselSlides = document.querySelectorAll('.carousel__slide');

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': true,
		'gutter': 16,
		'loop': false,
		'edgePadding': 16,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'1024': {
				'items': 3,
				'gutter': 24,
				'edgePadding': 0
			}
		}
	});

	var info = slider.getInfo();

	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);

	// GTM events
	// 1. Push visible slides to GTM
	// Find visible slides
	let visibleItems = [];
	var w = document.documentElement.clientWidth;

	var createSlideArray = function (slides) {
		let i;
		for (i = 0; i < slides.length; i++) {
			const slideItem = slides[i];

			if (slideItem.classList.contains('tns-slide-active')) {
				// Push visible items to array
				visibleItems.push(slideItem);
			}
		}

		// Determine if an element is fully in the visible viewport
		const thisCarousel = document.querySelector('.carousel__container[data-name="' + control +'"]');

		var observer = new IntersectionObserver(function(entries) {
			if (entries[0].isIntersecting === true) {

				// stop observing this carousel once it has been viewed
				if (viewedCarousels.includes(control)) {
					observer.unobserve(thisCarousel);
				} else {
					viewedCarousels.push(control);
					pushSlideTitleToGTM(visibleItems);
				}
			}
		}, { threshold: [0.1] });

		observer.observe(thisCarousel);
	}

	// Function for when scrolling through slider

	var moveSlideArray = function (slides, currentIndex) {
		let visibleOnScroll = [];
		let indexLimit;
		let i;

		currentIndex = currentIndex - 1;

		if (w <= 719) {
			indexLimit = currentIndex + 1;
		} else if (w >= 720 && w <= 1023) {
			indexLimit = currentIndex + 2;
		} else if (w >= 1024) {
			indexLimit = currentIndex + 3;
		}

		for (i = currentIndex; i < indexLimit; i++) {
			const slideItem = slides[i];

			// If this does not already exist in visibleOnScroll array
			if (!visibleItems.includes(slideItem)) {
				if (!visibleOnScroll.includes(slideItem)) {
					if (slideItem.classList.contains('tns-slide-active')) {
						// Push visible items to array
						visibleItems.push(slideItem);
						visibleOnScroll.push(slideItem);

						pushSlideTitleToGTM(visibleOnScroll);

						return false;
					}
				}
			}
		}

		pushSlideTitleToGTM(visibleOnScroll);
	}

	// Push title of slide to GTM datalayer
	var pushSlideTitleToGTM = function (slideArray) {
		// Push each item in array to GTM
		slideArray.forEach((item, i) => {
			let teaserTitle;
			// Assign teasterTitle var depending on which type of carousel
			if (item.classList.contains('pod-slide')) {
				teaserTitle = item.querySelector('.pod__title').innerText;
			} else {
				teaserTitle = item.querySelector('.teaser__text-heading').innerText;
			}

			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				// GTM tracking event
				dataLayer.push({
				  'event': 'gen_ev_card',
				  'event_category': 'card',
				  'event_action': 'view',
				  'event_label': teaserTitle,
				  'event_value': 0,
				  'non_interaction': true,
				  'card_view':1
				});
			}
		});
	}

	// Push visible slides on first load
	createSlideArray(info.slideItems);

	// Push visible slides when you navigate through carousel
	slider.events.on('indexChanged', function() {
		var info = slider.getInfo();
		var indexCurrent = info.displayIndex;

		moveSlideArray(info.slideItems, indexCurrent);
	});

	let j;
	for (j = 0; j < carouselSlides.length; j++) {
		let clickTitle;

		carouselSlides[j].onclick = function(e) {
			e.stopPropagation();

			if (this.classList.contains('teaser-slide')) {
				// Track click on a teaser
				clickTitle = this.querySelector('.teaser__text-heading').innerText;

			} else if (this.classList.contains('pod-slide')) {
				// Track click on a pod
				clickTitle = this.querySelector('.pod__title').innerText;
			};

			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				dataLayer.push({
				  'event': 'gen_ev_card',
				  'event_category': 'card',
				  'event_action': 'click',
				  'event_label': clickTitle,
				  'event_value': 0,
				  'non_interaction': false,
				  'card_click':1
				});
			}
		}
	}
});

// Teaser version 2 carousel
const teaserV2Containers = document.querySelectorAll('.carousel__container--teasers-v2');
// Array to track which carousels have been viewed
const viewedTeaserV2s = [];

forEach(teaserV2Containers, function (index, value) {
	let control = value.dataset.name;
	const carouselSlides = document.querySelectorAll('.carousel__slide');

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': true,
		'gutter': 8,
		'loop': false,
		'edgePadding': 24,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'1024': {
				'items': 3,
				'gutter': 24,
				'controls': true,
				'edgePadding': 0
			}
		}
	});

	var info = slider.getInfo();

	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);

	// GTM events
	// 1. Push visible slides to GTM
	// Find visible slides
	let visibleItems = [];
	var w = document.documentElement.clientWidth;

	var createSlideArray = function (slides) {
		let i;
		for (i = 0; i < slides.length; i++) {
			const slideItem = slides[i];

			if (slideItem.classList.contains('tns-slide-active')) {
				// Push visible items to array
				visibleItems.push(slideItem);
			}
		}

		// Determine if an element is fully in the visible viewport
		const thisCarousel = document.querySelector('.carousel__container--teasers-v2[data-name="' + control +'"]');

		var observer = new IntersectionObserver(function(entries) {
			if (entries[0].isIntersecting === true) {

				// stop observing this carousel once it has been viewed
				if (viewedTeaserV2s.includes(control)) {
					observer.unobserve(thisCarousel);
				} else {
					viewedTeaserV2s.push(control);
					pushSlideTitleToGTM(visibleItems);
				}
			}
		}, { threshold: [0.1] });

		observer.observe(thisCarousel);
	}

	// Function for when scrolling through slider

	var moveSlideArray = function (slides, currentIndex) {
		let visibleOnScroll = [];
		let indexLimit;
		let i;

		currentIndex = currentIndex - 1;

		if (w <= 719) {
			indexLimit = currentIndex + 1;
		} else if (w >= 720 && w <= 1023) {
			indexLimit = currentIndex + 2;
		} else if (w >= 1024) {
			indexLimit = currentIndex + 3;
		}

		for (i = currentIndex; i < indexLimit; i++) {
			const slideItem = slides[i];

			// If this does not already exist in visibleOnScroll array
			if (!visibleItems.includes(slideItem)) {
				if (!visibleOnScroll.includes(slideItem)) {
					if (slideItem.classList.contains('tns-slide-active')) {
						// Push visible items to array
						visibleItems.push(slideItem);
						visibleOnScroll.push(slideItem);

						pushSlideTitleToGTM(visibleOnScroll);

						return false;
					}
				}
			}
		}

		pushSlideTitleToGTM(visibleOnScroll);
	}

	// Push title of slide to GTM datalayer
	var pushSlideTitleToGTM = function (slideArray) {
		// Push each item in array to GTM
		slideArray.forEach((item, i) => {
			let teaserTitle = item.querySelector('.teaser__text-heading').innerText;

			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				// GTM tracking event
				dataLayer.push({
				  'event': 'gen_ev_card',
				  'event_category': 'card',
				  'event_action': 'view',
				  'event_label': teaserTitle,
				  'event_value': 0,
				  'non_interaction': true,
				  'card_view':1
				});
			}
		});
	}

	// Push visible slides on first load
	createSlideArray(info.slideItems);

	// Push visible slides when you navigate through carousel
	slider.events.on('indexChanged', function() {
		var info = slider.getInfo();
		var indexCurrent = info.displayIndex;

		moveSlideArray(info.slideItems, indexCurrent);
	});

	let j;
	for (j = 0; j < carouselSlides.length; j++) {
		let clickTitle;

		carouselSlides[j].onclick = function(e) {
			e.stopPropagation();

			clickTitle = this.querySelector('.teaser__text-heading').innerText;

			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				dataLayer.push({
				  'event': 'gen_ev_card',
				  'event_category': 'card',
				  'event_action': 'click',
				  'event_label': clickTitle,
				  'event_value': 0,
				  'non_interaction': false,
				  'card_click':1
				});
			}
		}
	}
});

// Footer carousel settings
const footerCarousel = document.querySelectorAll('.carousel__container--footer');

forEach(footerCarousel, function (index, value) {
	let control = value.dataset.name;

	var footerSlider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1.25,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': false,
		'gutter': 16,
		'loop': false,
		'edgePadding': 16,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'720': {
				'items': 3,
				'controls': false,
				'edgePadding': 0,
				'gutter': 24
			},
			'1024': {
				'items': 6,
				'controls': true,
				'edgePadding': 0
			}
		}
	});

	var info = footerSlider.getInfo();


	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info, eventName) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	footerSlider.events.on('newBreakpointEnd', toggleNoscroll);

});

// Story slider settings
const carouselContainerStory = document.querySelectorAll('.carousel__container--story');

forEach(carouselContainerStory, function (index, value) {
	let control = value.dataset.name;

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'preventScrollOnTouch': 'auto',
		'arrowKeys': true,
		'controls': true,
		'gutter': 16,
		'loop': false,
		'edgePadding': 16,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
	    '720': {
			'items': 1,
			'edgePadding': 80,
			'gutter': 24,
		},
	    '1024': {
			'items': 2,
			'edgePadding': 0
	    }
	  }
	});

	var info = slider.getInfo();


	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info, eventName) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);

});

// Slideshow containers
const slideshowContainers = document.querySelectorAll('.slideshow__container');

forEach(slideshowContainers, function (index, value) {
	let control = value.dataset.name;
	const slideshowSlides = document.querySelectorAll('.slideshow__slide');

	var slider = tns({
		'container': '.slideshow-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'navContainer': '.slideshow__nav-' + control,
		'items': 1,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': false,
		'loop': false,
		'controlsPosition': 'bottom',
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'720': {
				'center': false,
				'items': 1,
				'controls': false,
				'edgePadding': 0,
				'gutter': 24,
			},
			'1024': {
				'center': false,
				'items': 1,
				'controls': true,
				'edgePadding': 0
			}
		}
	});

	var slideshowInfo = slider.getInfo();

	// GTM events
	// 1. Push visible slides to GTM
	// Find visible slides
	let visibleItems = [];

	var createSlideArray = function (slides) {
		let i;
		for (i = 0; i < slides.length; i++) {
			const slideItem = slides[i];

			if (slideItem.classList.contains('tns-slide-active')) {
				// Push visible items to array
				visibleItems.push(slideItem);
			}
		}
		// Pop array to remove last item
		visibleItems.pop();

		// Determine if an element is fully in the visible viewport
		const thisCarousel = document.querySelector('.slideshow__container[data-name="' + control +'"]');

		var observer = new IntersectionObserver(function(entries) {
			if (entries[0].isIntersecting === true) {

				// stop observing this carousel once it has been viewed
				if (viewedCarousels.includes(control)) {
					observer.unobserve(thisCarousel);
				} else {
					viewedCarousels.push(control);
					pushSlideTitleToGTM(visibleItems);
				}
			}
		}, { threshold: [1] });

		observer.observe(thisCarousel);
	}

	// Function for when scrolling through slider
	var moveSlideArray = function (slides, currentIndex) {
		let visibleItems = [];
		let i;
		currentIndex = currentIndex - 1;

		for (i = currentIndex; i < slides.length; i++) {
			const slideItem = slides[i];

			if (slideItem.classList.contains('tns-slide-active')) {
				// Push visible items to array
				visibleItems.push(slideItem);
			}
		}

		if (visibleItems.length >= 2) {
			visibleItems.pop();
		}

		pushSlideTitleToGTM(visibleItems);
	}

	// Push title of slide to GTM datalayer
	var pushSlideTitleToGTM = function (slideArray) {
		// Push each item in array to GTM
		slideArray.forEach((item, i) => {
			let slideshowSlideTitle;
			let slideCaptionText;
			// Assign slideshowSlideTitle
			const slideCaption = item.querySelector('.caption');

			if (slideCaption !== null) {
				slideCaptionText = item.querySelector('.caption').innerText;
			} else {
				slideCaptionText = item.querySelector('.image__src').alt;
			}

			if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
				// GTM tracking event
				dataLayer.push({
				  'event': 'gen_ev_card',
				  'event_category': 'card',
				  'event_action': 'view',
				  'event_label': slideCaptionText,
				  'event_value': 0,
				  'non_interaction': true,
				  'card_view':1
				});
			}
		});
	}

	// Push visible slides on first load
	createSlideArray(slideshowInfo.slideItems);

	// Push visible slides when you navigate through carousel
	slider.events.on('indexChanged', function() {
		var info = slider.getInfo();
		var indexCurrent = info.displayIndex;

		moveSlideArray(info.slideItems, indexCurrent);
	});

	// On click push slide caption to GTM if it has one
	let i;
	for (i = 0; i < slideshowSlides.length; i++) {
		let clickTitle;

		slideshowSlides[i].onclick = function(e) {
			e.stopPropagation();

			const slideCaption = this.querySelector('.caption');

			if (slideCaption !== null) {
				const slideCaptionText = this.querySelector('.caption').innerText;

				if (typeof dataLayer !== 'undefined' && typeof doesAnalyticalCookieExist !== 'undefined') {
					dataLayer.push({
					  'event': 'gen_ev_card',
					  'event_category': 'card',
					  'event_action': 'click',
					  'event_label': slideCaptionText,
					  'event_value': 0,
					  'non_interaction': false,
					  'card_click':1
					});
				}
			}
		}
	}
});

// Thumbnails carousel settings
const carouselContainerThumbnail = document.querySelectorAll('.thumbnail-carousel__container');

forEach(carouselContainerThumbnail, function (index, value) {
	let control = value.dataset.name;

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 3,
		'lazyload': true,
		'preventScrollOnTouch': 'auto',
		'arrowKeys': true,
		'controls': true,
		'gutter': 16,
		'loop': false,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'1024': {
				'items': 4,
				'gutter': 24
			}
		}
	});

	var info = slider.getInfo();


	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info, eventName) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);

});

// story carousel
const storyCarouselContainers = document.querySelectorAll('.carousel__container--story-v2');

forEach(storyCarouselContainers, function (index, value) {
	let control = value.dataset.name;

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': true,
		'gutter': 16,
		'loop': false,
		'edgePadding': 16,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'slideBy': 1,
		'responsive': {
			'1024': {
				'items': 3,
				'gutter': 24,
				'edgePadding': 0
			}
		}
	});
});


// Child profile media carousel
const childMediaContainers = document.querySelectorAll('.carousel__container--child-profile-media');

forEach(childMediaContainers, function (index, value) {
	let control = value.dataset.name;

	var slider = tns({
		'container': '.carousel-' + control,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'arrowKeys': true,
		'preventScrollOnTouch': 'auto',
		'controls': true,
		'gutter': 16,
		'loop': false,
		'edgePadding': 16,
		'controlsPosition': 'top',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'slideBy': 1
	});

	var info = slider.getInfo();

	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info, eventName) {

		var items = info.items,
		slideItems = info.slideItems.length;

		if(slideItems <= items){
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);
});

// Thumbnails carousel settings
const socialProofWidget = document.querySelectorAll('.carousel__container--socialproofwidget');

forEach(socialProofWidget, function (index, value) {
	let control = value.dataset.name;

	var slider = tns({
		'container': '.carousel-' + control,
		'autoplay' : true,
		'controlsContainer': '.carousel__controls-' + control,
		'items': 1,
		'lazyload': true,
		'preventScrollOnTouch': 'auto',
		'arrowKeys': true,
		'controls': false,
		'gutter': 16,
		'loop': true,
		'controlsPosition': 'center',
		'nav': false,
		'mouseDrag': true,
		'speed': 400,
		'responsive': {
			'1024': {
				'items': 4
			},
			'768': {
				'items': 3
			},
			'480': {
				'items': 2
			},
			'320': {
				'items': 1
			}
		}
	});

	var info = slider.getInfo();


	// Toggle noScroll class for overflow on mobile carousels
	var toggleNoscroll = function (info, eventName) {

		var items = info.items,
			slideItems = info.slideItems.length;

		if (slideItems <= items) {
			value.classList.add('carousel__container--noScroll');
		} else {
			value.classList.remove('carousel__container--noScroll');
		}
	}

	toggleNoscroll(info);

	slider.events.on('newBreakpointEnd', toggleNoscroll);

});
